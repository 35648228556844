import React, { useState, useEffect } from 'react';
import {
  useNavigate
} from "react-router-dom";
import { IP } from "./config.js";


const Login = (props) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  let navigate = useNavigate();

  function login() {
    var form = new FormData();
    form.append("email", email);
    form.append("password", password);

    fetch(IP + '/api/login', {
      method: 'POST',
      body: form,
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      resp.json().then((json) => {
        if (!('error' in json)) {
          window.location.reload(false);
        }
      });
    });
  }

  useEffect(() => {

  })

  return (
        <>
          <div className="flex w-full justify-center">
            <div className="card w-96 bg-base-100 shadow-xl my-10">
              <figure className="px-10 pt-10 mb-4">
                <img className="mask mask-circle w-24" src={IP + "/static/match_logo.png"} alt="MATCH Logo" width='100' />
              </figure>
              <div className="card-body items-center text-center">
                <h2 className="card-title">Welcome back.</h2>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Email</span>
                  </label>
                  <label className="input-group">
                    <span>&middot;</span>
                    <input onChange={e => setEmail(e.target.value)} type="text" placeholder="info@site.com" className="input input-bordered" />
                  </label>
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Password</span>
                  </label>
                  <label className="input-group">
                    <span>&middot;</span>
                    <input onChange={e => setPassword(e.target.value)} type="password" placeholder="··············" className="input input-bordered" />
                  </label>
                </div>

                <div className="card-actions">
                  <button onClick={() => login()} className="btn btn-primary">Login</button>
                </div>
              </div>
            </div>
          </div>
      </>
  )
}
export default Login;
