import React, { useState, useEffect, useCallback } from 'react';
import Menu from './Menu.js';
import { IP } from "./config.js";
import debounce from 'lodash.debounce'


const Directory = (props) => {
  const [requests, setRequests] = useState([]);
  const [startups, setStartups] = useState([]);
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [stage, setStage] = useState("");
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState(false);

  function getRequests() {
    // var form = new FormData();
    // form.append("name", name);
    // form.append("project_str", "");

    fetch(IP + '/api/requests/get', {
      method: 'POST',
      // body: form,
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      resp.json().then((json) => {
        console.log(json);
        setRequests(json);
      });
    });
  }

  function getAllStartups() {
    // var form = new FormData();
    // form.append("name", name);
    // form.append("project_str", "");

    fetch(IP + '/api/startups/getall', {
      method: 'POST',
      // body: form,
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      resp.json().then((json) => {
        console.log(json);
        setStartups(json);
      });
    });
  }

  function getStartups(key, value) {
    var form = new FormData();
    form.append("name", name);
    form.append("foundation_date", date);
    form.append("stage", stage);
    form.append("desc", desc);

    form.set(key, value);

    fetch(IP + '/api/startups/get', {
      method: 'POST',
      body: form,
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      resp.json().then((json) => {
        console.log(json);
        setStartups(json);
      });
    });
  }

  const debouncedSearch = useCallback(debounce((key, e) => {
      getStartups(key, e.target.value);
      setLoading(false);
    }, 500), []
  )

  function handleSearchChange(key, e) {
    setLoading(true);
    debouncedSearch(key, e);
  }

  useEffect(() => {
    getRequests();
    getAllStartups();
  }, [])

  return (
      <>
<div className="flex flex-row preview border-base-300 bg-base-200 rounded-b-box rounded-tr-box flex min-h-[6rem] min-w-[18rem] flex-wrap items-left justify-top gap-2 overflow-x-hidden border bg-cover bg-top p-4 undefined w-full h-full" style={{backgroundSize: "5px 5px", backgroundImage: "radial-gradient(hsla(var(--bc)/.2) .5px,hsla(var(--b2)/1) .5px)", zIndex: 0}}>
      <Menu />
      <div className="menu bg-base-100 flex-1 p-2 rounded-box">
        <div className="flex flex-row items-center mt-2">
          <div className="form-control mx-2">
            <label className="input-group input-group-vertical">
              <span>Name</span>
              <input onChange={(e) => {setName(e.target.value); handleSearchChange('name', e)}} type="text" placeholder="E.g. rhome" className="input input-bordered" />
            </label>
          </div>

          <div className="form-control mx-2">
            <label className="input-group input-group-vertical">
              <span>Foundation date</span>
              <input onChange={(e) => {setDate(e.target.value); handleSearchChange('date', e)}} type="text" placeholder="E.g. 2022" className="input input-bordered" />
            </label>
          </div>

          <div className="form-control mx-2">
            <label className="input-group input-group-vertical">
              <span>Stage</span>
              <select onChange={(e) => {setStage(e.target.value); handleSearchChange('stage', e)}} className="select w-full max-w-xs input-bordered">
                <option disabled selected>Select</option>
                <option>Preseed</option>
                <option>Seed</option>
                <option>Series A</option>
                <option>Series B</option>
                <option>Series C</option>
                <option>Series D+</option>
              </select>
            </label>
          </div>

          <div className="form-control mx-2">
            <label className="input-group input-group-vertical">
              <span>Description</span>
              <input onChange={(e) => {setDesc(e.target.value); handleSearchChange('desc', e)}} type="text" placeholder="E.g. Machine Learning" className="input input-bordered" />
            </label>
          </div>

{ loading ?
          <button className="btn btn-circle btn-outline loading"></button>
: ""}
        </div>

        <div className="divider"></div> 

        <div className="flex flex-wrap">
{ startups.map((startup) => (
          <div className="card w-96 bg-base-100 shadow-xl mx-2 my-2" key={startup['id']}>
            <figure style={{height: 180}}><img src={startup['hero_image_url']} alt="Image" /></figure>
            <div className="avatar">
              <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2" style={{marginTop: '-50px', marginLeft: '260px', background: 'white'}}>
                <img src={startup['logo_url']} style={{objectFit: 'contain'}}/>
              </div>
            </div>
            <div className="card-body">
              <h2 className="card-title">
                { startup['name'] }
                <div className="badge badge-secondary">NEW</div>
              </h2>
              <p className="text-left">{ startup['desc'] }</p>
              <div className="card-actions justify-end">
                <div className="badge badge-outline">{ startup['stage'] }</div>
              </div>
            </div>
          </div>
))}
        </div>
      </div>

</div>
      </>
  )
}
export default Directory;
