import React, {useState, useEffect} from 'react';
import './App.css';
import Dashboard from './Dashboard.js';
import Directory from './Directory.js';
import Match from './Match.js';
import Login from './Login.js';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { IP } from './config.js';


function App() {
  const [user, setUser] = useState();

  useEffect(() => {
    fetch(IP + '/api/checklogin', {
      method: 'POST',
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      resp.json().then((json) => {
        console.log(json);
        setUser(json);
      });
    });
  }, []);

  return (
    <div className="App flex flex-col" data-theme="bumblebee">
      <Router>
{ user && 'id' in user ?
        <Routes>
          <Route path="/" element={<Dashboard user={user} />}></Route>
          <Route path="/dashboard" element={<Dashboard user={user} />}></Route>
          <Route path="/directory" element={<Directory user={user} />}></Route>
          <Route path="/match" element={<Match user={user} />}></Route>
        </Routes>
:
        <Routes>
          <Route path="*" element={<Login/>}></Route>
        </Routes>
}
      </Router>
    </div>
  );
}

export default App;
