import React from 'react';
import { IP } from './config.js';

const Menu = (props) => {
  function logout() {
    fetch(IP + '/api/logout', {
      method: 'POST',
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      resp.json().then((json) => {
        console.log(json);
        window.location.reload(false);
      });
    });
  }

  return (
      <>
        <ul className="menu bg-base-100 w-56 p-2 rounded-box">
          <li className="menu-title"><span>MATCH</span></li>
          <li><a href="/dashboard">Dashboard</a></li>
          <li><a href="/directory">Startups</a></li>
          <li><a href="/match">Match</a></li>
          <li className="menu-title"><span>USER</span></li>
          <li><a href="/profile">Profile</a></li>
          <li><a onClick={() => logout()}>Logout</a></li>
        </ul>
      </>
  )
}

export default Menu;
