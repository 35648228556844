import React, { useState, useEffect, useCallback } from 'react';
import Menu from './Menu.js';
import { IP } from "./config.js";


const Match = (props) => {
  const [requests, setRequests] = useState([]);
  const [desc, setDesc] = useState("");

  function getRequests() {
    // var form = new FormData();
    // form.append("name", name);
    // form.append("project_str", "");

    fetch(IP + '/api/requests/get', {
      method: 'POST',
      // body: form,
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      resp.json().then((json) => {
        console.log(json);
        setRequests(json);
      });
    });
  }

  function saveRequest() {
    if (desc === "") {
      alert("Error: Empty description!");
    }

    var form = new FormData();
    form.append("request", desc);

    fetch(IP + '/api/requests/save', {
      method: 'POST',
      body: form,
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      resp.json().then((json) => {
        console.log(json);
        getRequests();
        setDesc("");
      });
    });
  }

  function deleteRequest(id_) {
    var form = new FormData();
    form.append("id", id_);

    fetch(IP + '/api/requests/delete', {
      method: 'POST',
      body: form,
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      resp.json().then((json) => {
        console.log(json);
        getRequests();
      });
    });
  }

  function handleChange(e) {
    setDesc(e.target.value);
  }

  useEffect(() => {
    getRequests();
  }, [])

  return (
      <>
        <div className="flex flex-row preview border-base-300 bg-base-200 rounded-b-box rounded-tr-box flex min-h-[6rem] min-w-[18rem] flex-wrap items-left justify-top gap-2 overflow-x-hidden border bg-cover bg-top p-4 undefined w-full h-full" style={{backgroundSize: "5px 5px", backgroundImage: "radial-gradient(hsla(var(--bc)/.2) .5px,hsla(var(--b2)/1) .5px)", zIndex: 0}}>
          <Menu />
          <div className="menu bg-base-100 flex-1 p-2 rounded-box items-center">
            <div class="card w-1/2 bg-base-100 shadow-xl mt-5" style={{minWidth: 400}}>
              <div class="card-body">
                <h2 class="card-title">Add a request!</h2>
                <textarea onChange={(e) => handleChange(e)} className="textarea textarea-bordered" placeholder="Problem / idea" rows="5"></textarea>
                <div class="card-actions justify-end">
                  <button onClick={() => saveRequest()} className="btn btn-primary">Save</button>
                </div>
              </div>
            </div>

{ requests.length > 0 ?
             <div class="overflow-x-auto mx-5 my-5">
              <table class="table w-full">
                <thead>
                  <tr>
                    <th></th>
                    <th>Request</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
{ requests.map((request) => (
                  <tr>
                    <th>{ request['id'] }</th>
                    <td>{ request['request'] }</td>
                    <td>
                      <button onClick={() => deleteRequest(request['id'])} className="btn btn-circle btn-outline">
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                      </button>
                    </td>
                  </tr>
))}
                </tbody>
              </table>
            </div>
: ""}
          </div>
        </div>
      </>
  )
}
export default Match;
